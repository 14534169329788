<template>
	<Layout>
		<PageHeader
			:title="title"
			v-bind="actionButton"
			@openCreateTagModal="openCreateTagModal()"
		/>
		<TagsTable
			@editTag="openEditTagModal"
			@deleteTag="openDeleteTagModal"
			@updatePage="updatePage"
			@sortChanged="updateSort"
		/>
	</Layout>
</template>

<script>
import Layout from "../../layouts/main";
import { apiPatch, apiPost, apiDelete } from "@/utils/api";
import PageHeader from "@/components/page-header";
import TagsTable from "@/components/client-dashboard/tags-table";
import Swal from "sweetalert2";
import appConfig from "@/app.config";
import errorPopup from '@/utils/error-popup';

export default {
	page: {
		title: "Tags",
		meta: [{ name: "ClientTags", content: appConfig.description }]
	},
	name: 'Tags',
	components: { Layout, PageHeader, TagsTable },
	data() {
		return {
			title: "Tags",
			actionButton: {
				buttonText: 'New Tag',
				buttonEvent: 'openCreateTagModal',
				buttonClasses: 'btn-success',
				iconClasses: 'fas fa-plus',
				isDisabled: false,
				tooltip: null,
			},
			subscription: null,
		};
	},
	computed: {
		userInfo() {
			return this.$store.state.user.userInfo;
		},
		tableData() {
			return this.$store.state.tagsTable.tableData;
		},
		currentPage() {
			return this.$store.state.tagsTable.currentPage;
		},
		sort() {
			return this.$store.state.tagsTable.sort;
		},
		sortDesc() {
			return this.$store.state.tagsTable.sortDesc;
		},
	},
	watch: {
		userInfo(userInfo) {
			this.$store.dispatch('tagsTable/update', { userId: userInfo.id, page: 1, sort: 'text', sortDesc: 0 });		
		},
	},
	mounted() {
		this.$store.dispatch('user/refreshUserInfo');
	},
	methods: {
		updatePage(page) {
			this.$store.dispatch('tagsTable/update', { userId: this.userInfo.id, page, sort: this.sort, sortDesc: this.sortDesc })
		},
		updateSort(page, sort, sortDesc) {
			this.$store.dispatch('tagsTable/update', {
				userId: this.userInfo.id,
				page,
				sort,
				sortDesc,
				})
		},
		openCreateTagModal() {
			Swal.fire({
				title: "New Tag",
				text: "Enter text for the new tag",
				input: "text",
				showCancelButton: true,
				confirmButtonText: "OK",
				showLoaderOnConfirm: true,
				confirmButtonColor: "#556ee6",
				cancelButtonColor: "#f46a6a",
				inputValidator: (input) => {
					if (!input) {
						return 'This is a required field.';
					}
					const pattern = /^[a-zA-Z0-9-_]+$/;
					if (input.search(pattern) === -1) {
						return 'Please only use alphanumeric characters, dashes and underscores.';
					}
				},
				preConfirm: tagText => {
					return { tagText };
				},
				allowOutsideClick: true,
			}).then((result) => {
				if (result.isConfirmed) this.createTag(result.value.tagText);
			});
		},
		createTag(text) {
			apiPost(`users/${this.userInfo.id}/tags`, {
				text,
			})
				.then((result) => {
					Swal.fire({
						icon: "success",
						title: "Success!",
						html: `"${result.data.text}" tag was created.`,
						timer: 1000,
						showConfirmButton: false,
					});
					this.updatePage(this.currentPage);
				})
				.catch(error => errorPopup(error));
		},
		openEditTagModal(event, tagId) {
			Swal.fire({
				title: "Update Tag",
				text: "Enter new text for the tag",
				input: "text",
				showCancelButton: true,
				confirmButtonText: "OK",
				showLoaderOnConfirm: true,
				confirmButtonColor: "#556ee6",
				cancelButtonColor: "#f46a6a",
				inputValidator: (input) => {
					if (!input) {
						return 'This is a required field.'
					}
					const pattern = /^[a-zA-Z0-9-_]+$/;
					if (input.search(pattern) === -1) {
						return 'Please only use alphanumeric characters, dashes and underscores.';
					}
				},
				preConfirm: result => {
					return {
						id: tagId,
						text: result,
					}
				},
				allowOutsideClick: true
			}).then((result) => {
				if (result.isConfirmed) this.updateTag(result.value.id, result.value.text);
			});
		},
		updateTag(id, text) {
			const params = {
				text,
			};
			apiPatch(`tags/${id}`, params)
				.then((response) => {
					Swal.fire({
						icon: "success",
						title: "Success!",
						html: `Tag updated.`,
						timer: 1000,
						showConfirmButton: false,
					});
					this.updatePage(this.currentPage);
				})
				.catch(error => errorPopup(error));
		},
		openDeleteTagModal(event, tagId) {
			Swal.fire({
				title: "Delete Tag",
				text: "Are you sure you want to delete this tag? Any time entries that had this tag will remain.",
				showCancelButton: true,
				confirmButtonText: "OK",
				showLoaderOnConfirm: true,
				confirmButtonColor: "#556ee6",
				cancelButtonColor: "#f46a6a",
				allowOutsideClick: true,
			}).then((result) => {
				if (result.isConfirmed) this.deleteTag(tagId);
			});
		},
		deleteTag(tagId) {
			apiDelete(`tags/${tagId}`)
				.then(() => {
					Swal.fire({
						icon: "success",
						title: "Success!",
						html: `Tag deleted.`
					});
					this.updatePage(this.currentPage);
				})
				.catch(error => errorPopup(error));
		},
	},
};
</script>
