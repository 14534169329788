<template>
	<div class="row">
		<div class="col-12">
			<div class="card">
				<div class="card-body">
					<h4 class="card-title">
						Tags Table
					</h4>
					<div class="row mb-md-2" />
					<div v-if="tableData && tableData.length">
						<div
							class="table-responsive mb-0"
						>
							<b-table
								:items="tableData"
								:fields="fields"
								responsive="sm"
								:per-page="perPage"
								:sort-by.sync="sortBy"
								:sort-desc.sync="sortDesc"
								:no-local-sorting="true"
								@sort-changed="sortingChanged"
								:table-class="'table-centered'"
							>
								<template #cell(action)="data">
										<button
											class="btn btn-primary table-button"
											@click="$emit('editTag', $event, data.item.id)"
										>
											<i class="fas fa-pencil-alt" />
											Edit
										</button>
										<button
											class="btn btn-danger table-button"
											@click="$emit('deleteTag', $event, data.item.id)"
										>
											<i class="far fa-trash-alt" />
											Delete
										</button>
								</template>
								<template #cell(ProjectName)="data">
									<div v-if="data.item.project">
										{{ data.item.project.project_name }}
									</div>
								</template>
								<template #head(action)="field">
									<span></span>
								</template>
							</b-table>
						</div>
						<div class="row">
							<div class="col">
								<div class="dataTables_paginate paging_simple_numbers float-right">
									<ul class="pagination pagination-rounded mb-0">
										<b-pagination
											v-model="currentPage"
											:total-rows="totalItemsCount"
											:per-page="perPage"
											@input="getPaginatedTableData(currentPage)"
										/>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<div v-else-if="tableData">
						<h2 class="text-center empty-table">Nothing here yet!</h2>
						<p class="text-center text-muted">Try creating some tags to get started. Tags can also be created automatically with new time entries.</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import appConfig from "@/app.config";

export default {
	name: 'TagsTable',
	page: {
		title: "Tags Table",
		meta: [{ name: "ClientReportsTable", content: appConfig.description }]
	},
	data() {
		return {
			title: "Tags Table",
			currentPage: 1,
			perPage: 15,
			sortBy: "text",
			sortDesc: false,
			fields: [
				{ key: "text", sortable: true },
				{ key: "created_at", sortable: false, formatter: 'dateFormatter' },
				{ key: "updated_at", sortable: false, formatter: 'dateFormatter' },
				'action',
			],
		};
	},
	computed: {
		tableData() {
			return this.$store.state.tagsTable.tableData;
		},
		totalItemsCount() {
			return this.$store.state.tagsTable.totalItemsCount;
		},
	},
	methods: {
		getPaginatedTableData(currentPage) {
			this.$emit('updatePage', currentPage);
		},
		sortingChanged(ctx) {
			this.$emit('sortChanged', this.currentPage, ctx.sortBy, ctx.sortDesc ? 1 : 0);
		},
		dateFormatter(date) {
			return this.$moment(date).format('YYYY-MM-DD');
		},
	}
};
</script>

<style scoped>
.table-button {
	margin-right: 5px;
}

i {
	margin-right: 5px;
}

.empty-table {
	margin: 50px 0;
	color: #9ca8b3;
}
</style>